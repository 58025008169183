import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';
import './App.css';

class App extends Component {
  render() { return (
    <Router>
    <div class="site-wrapper">
      <nav className="navbar navbar-dark fixed-top flex-md-nowrap p-0">
        <ul className="navbar-nav px-3 bg-dark">
          <li className="nav-item text-nowrap d-none d-sm-none d-sm-block">
            {/* <a class="nav-link btn btn-lg" href="#"><i class="fas fa-wallet fa-fw text-almostwhite text-decoration-none"></i> <span id="account">{this.state.account.slice(0,4)}</span></a> */}
          </li>
        </ul>
      </nav>
      <div class="container-fluid">
        <Switch>
          <Route
            path="/roadmap"
            render={props => <Roadmap {...props} data={this.state} />}
          />
          <Route
            path="/projects"
            render={props => <Projects {...props} data={this.state} />}
          />
          <Route
            path="/safeshrimp"
            render={props => <Safeshrimp {...props} data={this.state} />}
          />
          <Route
            path="/chitin"
            render={props => <Chitin {...props} data={this.state} />}
          />
          <Route
            path="/"
            render={props => <Home {...props} data={this.state} />}
          />
        </Switch>
      </div>
    </div>
    </Router>
  )};
}

function Home(props) {
  return <div class="main-page">
  <div class="row justify-content-center align-items-center min-vh-100">
    <div class="col-sm-9 col-warning">Shrimp Capital</div><div class="col-sm-12"></div>
    <div class="col-sm-6 col-xl-6">
      <div class="card mx-auto text-almostwhite bg-transparent border-0">
        <img src="/img/shrimp2t.png" class="card-img border-0 rounded-0" alt="Shrimp Capital" />
        <div class="card-img-overlay h-100 d-flex flex-column justify-content-end p-2 text-center">
        </div>
      </div>
      <div class="container">
        <div class="row card-text text-center text-almostwhite p-2">
          <a class="col btn btn-white" target="_blank" rel="noreferrer" href="https://twitter.com/Shrimp_Capital"><div class="col"><i class="fab fa-twitter fa-fw text-almostwhite text-decoration-none"></i></div></a>
          <a class="col btn btn-white" target="_blank" rel="noreferrer" href="https://shrimpcapital.medium.com/"><div class="col"><i class="fab fa-medium-m fa-fw text-almostwhite text-decoration-none"></i></div></a>
          <a class="col btn btn-white" target="_blank" rel="noreferrer" href="https://t.me/ShrimpCapital"><div class="col"><i class="fab fa-telegram-plane fa-fw text-almostwhite text-decoration-none"></i></div></a>
            <a class="col btn btn-white" target="_blank" rel="noreferrer" href="https://discord.gg/XncvtUKmGz"><div class="col"><i class="fab fa-discord fa-fw text-almostwhite text-decoration-none"></i></div></a>
        </div>
        <div class="row card-text text-center text-almostwhite p-2">
          <div class="col-xl-6"><a class="btn btn-lg btn-white-inv btn-fill" href="/projects"><i class="fas fa-project-diagram fa-fw text-decoration-none"></i> Projects</a></div>
          <div class="col-xl-6"><a class="btn btn-lg btn-white-inv btn-fill" href="/roadmap"><i class="fas fa-road fa-fw text-decoration-none"></i> Roadmap</a></div>
          <div class="col-xl-6"><a class="btn btn-lg btn-white-inv btn-fill" href="/safeshrimp"><i class="fas fa-life-ring fa-fw text-decoration-none"></i> SafeShrimp</a></div>
          <div class="col-xl-6"><a class="btn btn-lg btn-white-inv btn-fill" href="/chitin"><i class="fas fa-shield-alt fa-fw text-decoration-none"></i> Chitin</a></div>
          <div class="col-xl-6"><a class="btn btn-lg btn-white btn-fill" target="_blank" rel="noreferrer" href="https://smonk.shrimp.capital"><i class="fas fa-chess-knight fa-fw text-almostwhite text-decoration-none"></i> Sea Monkey</a></div>
          <div class="col-xl-6"><a class="btn btn-lg btn-white btn-fill" target="_blank" rel="noreferrer" href="https://cryptomancy.net"><i class="fas fa-sun fa-fw text-almostwhite text-decoration-none"></i> Cryptomancy</a></div>
          <div class="col-xl-6 offset-xl-3"><a class="btn btn-lg btn-white btn-fill" target="_blank" rel="noreferrer" href="https://v1exchange.pancakeswap.finance/#/swap?outputCurrency=0xeeeeeeee82a747508b66431cfe9443ace4e30881&inputCurrency=BNB"><i class="fas fa-plus fa-fw text-almostwhite text-decoration-none"></i> Buy SHRMP</a></div>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center align-items-center min-vh-100" id="maintext">
    <div class="col-lg-9">
      <div class="card text-almostwhite border-0">
        <div class="row no-gutters">
          <div class="col-sm-4">
            <img src="/img/shrimp.png" class="card-img border-0 rounded-0" alt="Shrimp Capital" />
          </div>
          <div class="col-sm-8">
            <div class="card-body d-flex flex-column h-100">
              <div class="row justify-content-center">
                <div class="col-xl-12"><h1 class="card-title text-gradient">Navigating rogue waves with efficiency and precision</h1></div>
              </div>
              <p class="card-text mt-auto">Forged in the fires of DeFi Summer, Shrimp Capital has learned to traverse the channels of CeDefi with ease and adaptability. We are industry experts and invest in positive cash-flow, high interest bearing platforms. The code of the crustacean is sacred. We honor it, we protect it, and we attack those who threaten it. This is our mission statement.</p>
              <h3 class="card-title text-gradient">About Shrimp Capital</h3>
              <p class="card-text mt-auto">Shrimp Capital is a Micro VC that has emerged to enhance the future of financial instruments on the Binance Smart Chain.</p>
              <p class="card-text">It is comprised of the top minds of Decentralised Finance (DeFi) who have come together to incubate various projects and build out the BSC ecosystem. New entrants looking for a solution to the expensive costs of DeFi on the Ethererum network have found Binance Smart Chain to be an attractive alternative.</p>
              <p class="card-text">The SHRMP token smart contract is designed to burn 2.5% per transfer, and will have many more use cases including access to premium syndication.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center align-items-center min-vh-100" id="token">
    <div class="col-lg-9">
      <div class="card text-almostwhite border-0">
        <div class="row no-gutters">
          <div class="col-sm-4">
            <img src="/img/card.png" class="card-img border-0 rounded-0" alt="token" />
          </div>
          <div class="col-sm-8">
            <div class="card-body d-flex flex-column h-100">
              <div class="row justify-content-center">
                <div class="col-xl-6"><h1 class="card-title"><a href="https://v1exchange.pancakeswap.finance/#/swap?outputCurrency=0xeeeeeeee82a747508b66431cfe9443ace4e30881&inputCurrency=BNB" class="text-white text-decoration-none text-gradient">SHRMP Token</a></h1></div>
                <div class="col-xl-6"></div>
              </div>
                  <p class="card-text mt-auto">Shrimp Finance is a community of high net worth individuals that have pooled together to create the future of financial instruments on the Binance Smart Chain. It is comprised of the top minds of Decentralised Finance (DeFi) who have come together as one Shrimp to usher in the aeon of Horus and make it big in the sea of cryptocurrency.</p>
                  <p class="card-text">The SHRMP token smart contract is designed to burn 2.5% per transfer, and can be used to access premium syndication including our private Telegram group and more.</p>
                  <p class="card-text">The initial supply is set to 93,418,777 SHRMP tokens.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center align-items-center min-vh-100" id="shrimp">
    <div class="col-lg-9">
      <div class="card text-almostwhite border-0">
        <div class="row no-gutters">
          <div class="col-sm-4">
            <img src="/img/bob.gif" class="card-img border-0 rounded-0" alt="shrimp" />
          </div>
          <div class="col-sm-8">
            <div class="card-body d-flex flex-column h-100">
              <div class="row justify-content-center">
                <div class="col-xl-12"><h3 class="card-title text-gradient">APIHP (Allocation Procurement Incentivised Holding Program)</h3></div>
              </div>
                  <p class="card-text mt-auto">The SHRMP token will be used to facilitate allocations alongside shrimp executives. Holding SHRMP token will grant you access to exclusive deals within the community, and larger allocations according to SHRMP held.</p>
                  <p class="card-text">Standings are as follows:
                    <ul>
                      <li>10,000 SHRMP - shrimp</li>
                      <li>20,000-100,000 SHRMP - jumbo shrimp</li>
                      <li>100,000-500,000 SHRMP - lobster</li>
                      <li>500,000-1,000,000 SHRMP - shark</li>
                      <li>>1,000,000 SHRMP - whale</li>
                    </ul>
                    NOTE: The number of SHRMP per tier is subject to change as we move forward.</p>
                  <p class="card-text">As SHRMP tokens are inherently deflationary, it will become increasingly more difficult to move up in standing.</p>
                  <p class="card-text">A held SHRMP could be worth the allocation of your lifetime.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
}

class Roadmap extends App {
  render(){
    return <div>
    <div class="row align-items-center min-vh-100 text-almostwhite">
      <Sidebar />
      <div class="col-sm-8">
        <h1 class="text-gradient">Roadmap</h1>
        <p>
          <ul style={{listStyleType:'none'}}>
            <li><i class="fas fa-check-square fa-fw text-almostwhite text-decoration-none"></i> <a class="link-white text-gradient" href="https://cryptomancy.net/" target="_blank" rel="noreferrer">Build NFT project with buy and burn scheme.</a></li>
            <li><i class="fas fa-check-square fa-fw text-almostwhite text-decoration-none"></i> <a class="link-white text-gradient" href="https://smonk.shrimp.capital/" target="_blank" rel="noreferrer">Build SMONK farm, providing high yield farming to SHRMP holders.</a></li>
            <li><i class="fas fa-check-square fa-fw text-almostwhite text-decoration-none"></i> <a class="link-white text-gradient" href="/safeshrimp" rel="noreferrer">Build SafeShrimp project with exclusive presale for SHRMP holders.</a></li>
            <li><i class="fas fa-check-square fa-fw text-almostwhite text-decoration-none"></i> <a class="link-white text-gradient" href="https://discord.gg/XncvtUKmGz" target="_blank" rel="noreferrer">Create public Discord server for community members.</a></li>
            <li><i class="fas fa-check-square fa-fw text-almostwhite text-decoration-none"></i> <a class="link-white text-gradient" href="https://discord.gg/XncvtUKmGz" target="_blank" rel="noreferrer">Build bot to check balances for access to private Discord channels with exclusive calls.</a></li>
            <li><i class="fas fa-check-square fa-fw text-almostwhite text-decoration-none"></i> <a class="link-white text-gradient" href="/chitin" rel="noreferrer">Launch Chitin audit service.</a></li>
            <li><i class="fas fa-square fa-fw text-almostwhite text-decoration-none"></i> Secure presale allocations for SHRMP holders.</li>
            <li><i class="fas fa-square fa-fw text-almostwhite text-decoration-none"></i> Expand on the private alpha Discord group.</li>
            <li><i class="fas fa-square fa-fw text-almostwhite text-decoration-none"></i> Explore opportunities for cross-chain activities, including Fantom, Matic, and Solana.</li>
          </ul>
        </p>
        <p class="card-text"><b>Shrimp Capital expects these projects to be delivered by the end of July 2021.</b></p>
        <p class="card-text">This roadmap is just a taste of what's to come - we aim to establish Shrimp Capital as the premier syndicate of blockchain developers and project incubators on the Binance Smart Chain. Our goal is to become a badge of trust - when you see the Shrimp logo, you can feel safe putting your money in that contract.</p>
      </div>
      <div class="col-sm-1"></div>
    </div>
    </div>
  }
  }

class Safeshrimp extends App {
  render(){
    return <div>
    <div class="row align-items-center min-vh-100 text-almostwhite">
      <Sidebar />
      <div class="col-xl-8">
        <h1 class="text-gradient"><a class="link-white" target="_blank" href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x928965a440fbd978d20f5c52d70b89127539c8c9&inputCurrency=BNB" rel="noreferrer">SafeShrimp</a></h1>
        <p class="card-text">SafeShrimp is our newest defi protocol that we are excited to bring to our community. A token by the name of “SafeMoon” has been making waves across the space, even stretching on to mainstream media attention. Many projects have popped up as of recent, attempting to replicate and fork this success. Unfortunately they are all missing vital components that the original SafeMoon project had.</p>
        <p class="card-text">One of the biggest criticisms of SafeMoon is that the liquidity is not locked. This is a valid concern, because as the liquidity pair continues to grow, it creates more and more risk for token holders and newer participants.</p>
        <p class="card-text">We believe that the sewers are dangerous enough, without having to worry about whether or not a dev/team is going to pull the rug out from under you. We wanted to create a project that was safe for its users to buy and sell - whatever happens to the price is outside our control, but we feel that you shouldn’t have to worry about getting rugged. That was the primary motivation behind the Shrimp Capital team burning their LP tokens on $SHRMP.</p>
        <p class="card-text">However, we also wanted to create a project where we wouldn’t have to worry about liquidity. We saw the success of SafeMoon and felt we could improve upon its design, while also rewarding the SHRMP community. We saw that SMONK fractured our community when it was our intention to unite Shrimpers under the <span class="text-gradient">code of the crustacean</span>. Our solution? <span class="text-gradient">SafeShrimp</span></p>
        <h3 class="text-gradient">How it works</h3>
        <p class="card-text">If you’re unfamiliar with SafeMoon, basically the contract takes an 8% fee when users buy and sell the token. 4% of the fee is reflected to all holders, while the other 4% is used to add liquidity to the project. When a specific threshold is reached, the contract sells half of the tokens it collects via the transfer fee, and then uses the BNB it sold to create a liquidity pair. This creates an exponentially growing liquidity system, allowing users to participate freely without having to worry about slippage or price impact.</p>
        <h3 class="text-gradient"><a class="link-white" href="https://smonk.shrimp.capital/" target="_blank" rel="noreferrer">Farming</a></h3>
        <p class="card-text">Farming will be available for the SafeShrimp token on the SMONK platform. A pool will be added to Sea Monkey farming to allow you to stake a SAFESHRIMP-BNB LP token and receive SMONK in return.</p>
        <h3 class="text-gradient">What about $SHRMP?</h3>
        <p class="card-text">Now for the good stuff. We wanted to launch a new project that was inclusive for current $SHRMP holders, while also creating new opportunities for new participants and members of our community. Our solution? <span class="text-gradient">Universal Basic Shrimpcome</span></p>
        <p class="card-text">We have set aside 5% of the total supply of SafeShrimp to be distributed to $SHRMP holders on a regular basis. These tokens will be distributed based on the weight of your $SHRMP holdings, with snapshots taken at a random time. The more $SHRMP you hold, the more SafeShrimp you will receive.</p>
        <div class="d-flex">
          <p class="card-text flex-fill"><h2><span class="text-gradient">Hold $SHRMP
          <br />Get free SafeShrimp
          <br />It’s that simple.</span></h2></p>
          <p class="card-text flex-fill"><a class="btn btn-lg btn-white btn-fill btn-fill-h" target="_blank" rel="noreferrer" href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x928965a440fbd978d20f5c52d70b89127539c8c9&inputCurrency=BNB"><i class="fas fa-plus fa-fw text-almostwhite text-decoration-none"></i>&nbsp;Buy SAFESHRIMP</a></p>
        </div>
        <table class="table text-almostwhite">
          <tbody>
            <tr class="table-borderless">
              <th scope="row" class="text-gradient">Token</th>
              <td>SAFESHRIMP</td>
            </tr>
            <tr>
              <th scope="row" class="text-gradient">Contract</th>
              <td><a class="link-white" target="_blank" href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x928965a440fbd978d20f5c52d70b89127539c8c9&inputCurrency=BNB" rel="noreferrer">0x928965a440fbd978d20f5c52d70b89127539c8c9</a></td>
            </tr>
            <tr>
              <th scope="row" class="text-gradient">Max Supply</th>
              <td>3,500,000,000</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-xl-1"></div>
    </div>
    </div>
  }
}

class Chitin extends App {
  render(){
    return <div>
    <div class="row align-items-center min-vh-100 text-almostwhite">
      <Sidebar />
      <div class="col-xl-8">
        <h1 class="text-gradient">Chitin</h1>
        <p class="card-text flex-fill"><blockquote class="text-gradient">The exoskeleton of a shrimp is made of a hardened protein substance called <i>Chitin</i>. This substance is the shield that protects crustaceans from predators.</blockquote></p>
        <p class="card-text">As veteran developers, we have seen the quick copy-paste audits that plague the ecosystem. Low effort audits are performed with automated scripts to find only the lowest of hanging fruit. Inexperienced auditors perform cursory glances at contract code and make sweeping judgements about safety.</p>
        <p class="card-text">At Shrimp Capital we know how to do this better. With many years of collective experience under our belts both in DeFi and across many programming languages, we have the insights to perform in-depth bespoke audits of contracts that take context into account. We don't just look at technical vulnerabilities, but risks present in business logic and tokenomics.</p>
        <h3 class="text-gradient">How it works</h3>
        <p class="card-text">All you need to do is contact the team via Discord or email <a class="text-gradient" href="mailto:shrimpcapital@protonmail.com">shrimpcapital@protonmail.com</a>. Let us know the details of your project, including contract addresses, token names, logos, and any information you feel is relevant to the audit. We'll provide you with a formal quote based on the complexity of the project and we'll proceed once accepted.</p>
        <h3 class="text-gradient">Shrimp Ecosystem</h3>
        <p class="card-text">Like all Shrimp Capital projects, Chitin directly benefits holders of the <span class="text-gradient">$SHRMP</span> token. In this case, the benefits come from 25% of audit fees going directly to buy and burn <span class="text-gradient">$SHRMP</span>. Verified members of the community can also nominate projects for free audits.</p>
      </div>
      <div class="col-xl-1"></div>
    </div>
    </div>
  }
}

class Projects extends App {
  render(){
    return <div>
    <div class="row align-items-center min-vh-100 text-almostwhite">
      <Sidebar />
      <div class="col-xl-8">
        <h1 class="text-gradient">Projects</h1>
        <h3><a class="link-white text-gradient" href="https://smonk.shrimp.capital/" target="_blank" rel="noreferrer">Chitin</a></h3>
        <div class="float-right"><img src="/img/smonk.png" class="card-img border-0 rounded-0" alt="smonk" /></div>
        <p class="card-text">As veteran developers, we have seen the quick copy-paste audits that plague the ecosystem. Low effort audits are performed with automated scripts to find only the lowest of hanging fruit. Inexperienced auditors perform cursory glances at contract code and make sweeping judgements about safety.</p>
        <p class="card-text">At Shrimp Capital we know how to do this better. With many years of collective experience under our belts both in DeFi and across many programming languages, we have the insights to perform in depth bespoke audits of contracts that take context into account. We don't just look at technical vulnerabilities, but risks present in business logic and tokenomics.</p>
        <p class="card-text">25% of all audit sales go directly back to Shrimp holders in the form of token burns.</p>
        <p class="card-text"><i><a class="link-white" href="/chitin" rel="noreferrer">Read more...</a></i></p>
        <h3><a class="link-white text-gradient" href="/safeshrimp" rel="noreferrer">SafeShrimp</a></h3>
        <p class="card-text">The SafeShrimp token is similar to other Safe tokens like SafeMoon - a 2% fee is taken on every transaction, with 1% given to all holders, and the other 1% added to the liquidity pool. This method provides a fair and safe way for tokens to appreciate in value with volume.</p>
        <p class="card-text">Farming will be available for the SafeShrimp token on the SMONK platform.</p>
        <p class="card-text">Additionally, airdrops of SafeShrimp for SHRMP holders will occur in the future.</p>
        <p class="card-text"><i><a class="link-white" href="/safeshrimp" rel="noreferrer">Read more...</a></i></p>
        <h3><a class="link-white text-gradient" href="https://cryptomancy.net/" target="_blank" rel="noreferrer">Cryptomancy</a></h3>
        <p class="card-text">The Cryptomancy project is a series of NFTs (non-fungible tokens) on the Binance Smart Chain designed around the concepts of tarot, chaos magic, sigils, and technomancy. A series of 1471 total hand crafted NFTs have been created, each one totally unique and assembled from a group of magical elements.</p>
        <p class="card-text">33% of original card sales went towards a "LP and Burn" operation on the SHRMP token at the conclusion of the sale. This meant market buying almost 250 BNB worth of SHRMP and adding it to the liquidity pool.</p>
        <p class="card-text">Additionally, plans are in the pipeline to further reward holders of Cryptomancy NFTs through future Shrimp Capital campaigns.</p>
        <p class="card-text"><i><a class="link-white" href="https://cryptomancy.net/" target="_blank" rel="noreferrer">Read more...</a></i></p>
        <h3><a class="link-white text-gradient" href="https://smonk.shrimp.capital/" target="_blank" rel="noreferrer">Sea Monkey (SMONK)</a></h3>
        <p class="card-text">As a long-term vision of our SHRMP ecosystem, SHRMP holders will be given access to allocations in our upcoming investments. These projects would ideally have their liquidity provided by the SMONK yield-farm platform creating a well-rounded ecosystem.</p>
        <p class="card-text">SHRMP holders benefit from the ability to earn yield by providing liquidity within the Sea Monkey Farm. This will further incentivize holding SHRMP, which will be necessary to enter into private rounds that are offered in the shrimp Capital ecosystem.</p>
        <p class="card-text"><i><a class="link-white" href="https://shrimpcapital.medium.com/thsea-monkey-farm-a-shrimp-capital-project-smonk-5c9209b6ae60" target="_blank" rel="noreferrer">Read more...</a></i></p>
      </div>
      <div class="col-sm-1"></div>
    </div>
    </div>
  }
}

function Sidebar(props) {
  return <div class="col-xl-4">
    <div class="card mx-auto text-almostwhite bg-transparent border-0">
      <Link to="/"><img src="/img/shrimp2t.png" class="card-img border-0 rounded-0" alt="cryptomancy" />
      <div class="card-img-overlay h-100 d-flex flex-column justify-content-end p-2 text-center">
      </div></Link>
    </div>
    <div class="container">
      <div class="row card-text text-center text-almostwhite p-2">
        <a class="col btn btn-white" target="_blank" rel="noreferrer" href="https://twitter.com/Shrimp_Capital"><div class="col"><i class="fab fa-twitter fa-fw text-almostwhite text-decoration-none"></i></div></a>
        <a class="col btn btn-white" target="_blank" rel="noreferrer" href="https://shrimpcapital.medium.com/"><div class="col"><i class="fab fa-medium-m fa-fw text-almostwhite text-decoration-none"></i></div></a>
        <a class="col btn btn-white" target="_blank" rel="noreferrer" href="https://t.me/ShrimpCapital"><div class="col"><i class="fab fa-telegram fa-fw text-almostwhite text-decoration-none"></i></div></a>
        <a class="col btn btn-white" target="_blank" rel="noreferrer" href="https://discord.gg/XncvtUKmGz"><div class="col"><i class="fab fa-discord fa-fw text-almostwhite text-decoration-none"></i></div></a>
      </div>
      <div class="row row card-text text-center text-almostwhite p-2">
      <div class="col-xl-12"><a class="btn btn-lg btn-white-inv btn-fill" href="/projects"><i class="fas fa-project-diagram fa-fw text-decoration-none"></i> Projects</a></div>
      <div class="col-xl-12"><a class="btn btn-lg btn-white-inv btn-fill" href="/roadmap"><i class="fas fa-road fa-fw text-decoration-none"></i> Roadmap</a></div>
      <div class="col-xl-12"><a class="btn btn-lg btn-white-inv btn-fill" href="/safeshrimp"><i class="fas fa-life-ring fa-fw text-decoration-none"></i> SafeShrimp</a></div>
      <div class="col-xl-12"><a class="btn btn-lg btn-white-inv btn-fill" href="/chitin"><i class="fas fa-shield-alt fa-fw text-decoration-none"></i> Chitin</a></div>
      <div class="col-xl-12"><a class="btn btn-lg btn-white btn-fill" target="_blank" rel="noreferrer" href="https://smonk.shrimp.capital"><i class="fas fa-chess-knight fa-fw text-almostwhite text-decoration-none"></i> Sea Monkey</a></div>
      <div class="col-xl-12"><a class="btn btn-lg btn-white btn-fill" target="_blank" rel="noreferrer" href="https://cryptomancy.net"><i class="fas fa-sun fa-fw text-almostwhite text-decoration-none"></i> Cryptomancy</a></div>
      <div class="col-xl-12"><a class="btn btn-lg btn-white btn-fill" target="_blank" rel="noreferrer" href="https://v1exchange.pancakeswap.finance/#/swap?outputCurrency=0xeeeeeeee82a747508b66431cfe9443ace4e30881&inputCurrency=BNB"><i class="fas fa-plus fa-fw text-almostwhite text-decoration-none"></i> Buy SHRMP</a></div>
      </div>
    </div>
  </div>
}

export default App;
